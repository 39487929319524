var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":_vm.onCancelCreator}},[_vm._v("閉じる")]),_c('v-spacer'),_vm._v(" 新規伝票 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommitCreator}},[_vm._v("決定")])],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('XFieldSet',{attrs:{"label":"売上日"}},[_c('validation-provider',{attrs:{"name":"売上日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"売上日","error-messages":errors},model:{value:(_vm.creator.date),callback:function ($$v) {_vm.$set(_vm.creator, "date", $$v)},expression:"creator.date"}})]}}])})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('XFieldSet',{attrs:{"label":"請求書発行日"}},[_c('validation-provider',{attrs:{"name":"請求書発行日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"請求書発行日","error-messages":errors},model:{value:(_vm.creator.invoiceDate),callback:function ($$v) {_vm.$set(_vm.creator, "invoiceDate", $$v)},expression:"creator.invoiceDate"}})]}}])})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('XFieldSet',{attrs:{"label":"入金日"}},[_c('validation-provider',{attrs:{"name":"入金日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"入金日","error-messages":errors},model:{value:(_vm.editor.depositDate),callback:function ($$v) {_vm.$set(_vm.editor, "depositDate", $$v)},expression:"editor.depositDate"}})]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"区分","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"*区分","items":[
                { text: _vm.$options.filters.saleType(1), value: 1 },
                { text: _vm.$options.filters.saleType(1 << 1), value: 1 << 1 },
                { text: _vm.$options.filters.saleType(1 << 2), value: 1 << 2 },
                { text: _vm.$options.filters.saleType(1 << 3), value: 1 << 3 },
                { text: _vm.$options.filters.saleType(1 << 5), value: 1 << 5 } ],"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.creator.type),callback:function ($$v) {_vm.$set(_vm.creator, "type", $$v)},expression:"creator.type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"商品名","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"商品名/摘要","counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.description),callback:function ($$v) {_vm.$set(_vm.creator, "description", $$v)},expression:"creator.description"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"金額","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","rules":[function (v) { return !!v || '金額 は必須です'; }],"counter":"","maxlength":10,"error-messages":errors,"label":"*金額","clearable":"","suffix":"円"},model:{value:(_vm.creator.amount),callback:function ($$v) {_vm.$set(_vm.creator, "amount", _vm._n($$v))},expression:"creator.amount"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"伝票サマリ種別","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"*伝票サマリ種別","items":[
                { text: 'なし', value: 1 },
                { text: '日', value: 1 << 1 },
                { text: '月', value: 1 << 2 },
                { text: '年', value: 1 << 5 } ],"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.creator.salesummaryType),callback:function ($$v) {_vm.$set(_vm.creator, "salesummaryType", $$v)},expression:"creator.salesummaryType"}})]}}])})],1)],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"請求内容","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"請求内容","items":_vm.invoices,"item-text":"name","item-value":"code","error-messages":errors,"return-object":"","required":"","outlined":""},model:{value:(_vm.creator.invoice),callback:function ($$v) {_vm.$set(_vm.creator, "invoice", $$v)},expression:"creator.invoice"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"支店","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"支店","item-text":"name","item-value":"code","items":_vm.branchs,"rules":[function (v) { return !!v.code || '支店 は必須です'; }],"error-messages":errors,"return-object":""},model:{value:(_vm.creator.branch),callback:function ($$v) {_vm.$set(_vm.creator, "branch", $$v)},expression:"creator.branch"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"チーム","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"チーム","item-text":"name","item-value":"code","items":_vm.teams,"rules":[function (v) { return !!v.code || 'チーム は必須です'; }],"error-messages":errors,"return-object":""},model:{value:(_vm.creator.team),callback:function ($$v) {_vm.$set(_vm.creator, "team", $$v)},expression:"creator.team"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"担当者","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.employees,"label":"担当者","item-text":"name","item-value":"code","return-object":"","rules":[function (v) { return !!v.code || '担当者 は必須です'; }],"error-messages":errors,"outlined":""},model:{value:(_vm.creator.employee),callback:function ($$v) {_vm.$set(_vm.creator, "employee", $$v)},expression:"creator.employee"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"顧客名","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.facilities,"item-text":"name","item-value":"code","label":"顧客名","error-messages":errors,"return-object":"","required":""},model:{value:(_vm.creator.facility),callback:function ($$v) {_vm.$set(_vm.creator, "facility", $$v)},expression:"creator.facility"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"都道府県","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.prefs,"label":"都道府県","item-text":"name","item-value":"code","error-messages":errors,"return-object":"","required":"","outlined":"","readonly":""},model:{value:(_vm.creator.facility.pref),callback:function ($$v) {_vm.$set(_vm.creator.facility, "pref", $$v)},expression:"creator.facility.pref"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"地域","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"地域","counter":"","maxlength":100,"error-messages":errors,"readonly":""},model:{value:(_vm.creator.facility.areaName),callback:function ($$v) {_vm.$set(_vm.creator.facility, "areaName", $$v)},expression:"creator.facility.areaName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.creator.memo),callback:function ($$v) {_vm.$set(_vm.creator, "memo", $$v)},expression:"creator.memo"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }