<template>
  <v-card>
    <v-card-title>
      <v-btn text color="warning" @click="onCancelCreator">閉じる</v-btn>
      <v-spacer />
      新規伝票
      <v-spacer />

      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitCreator">決定</v-btn>
    </v-card-title>

    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="4">
            <XFieldSet label="売上日">
              <validation-provider v-slot="{ errors }" name="売上日" rules="">
                <DateTime label="売上日" v-model="creator.date" :error-messages="errors" />
              </validation-provider>
            </XFieldSet>
          </v-col>

          <v-col cols="4">
            <XFieldSet label="請求書発行日">
              <validation-provider v-slot="{ errors }" name="請求書発行日" rules="">
                <DateTime label="請求書発行日" v-model="creator.invoiceDate" :error-messages="errors" />
              </validation-provider>
            </XFieldSet>
          </v-col>

          <v-col cols="4">
            <XFieldSet label="入金日">
              <validation-provider v-slot="{ errors }" name="入金日" rules="">
                <DateTime label="入金日" v-model="editor.depositDate" :error-messages="errors" />
              </validation-provider>
            </XFieldSet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <validation-provider v-slot="{ errors }" name="区分" rules="">
              <v-select
                label="*区分"
                v-model="creator.type"
                :items="[
                  { text: $options.filters.saleType(1), value: 1 },
                  { text: $options.filters.saleType(1 << 1), value: 1 << 1 },
                  { text: $options.filters.saleType(1 << 2), value: 1 << 2 },
                  { text: $options.filters.saleType(1 << 3), value: 1 << 3 },
                  { text: $options.filters.saleType(1 << 5), value: 1 << 5 },
                ]"
                :error-messages="errors"
                required
                outlined
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider v-slot="{ errors }" name="商品名" rules="max:100">
              <v-text-field label="商品名/摘要" v-model="creator.description" counter :maxlength="100" :error-messages="errors" />
            </validation-provider>
          </v-col>

          <v-col cols="4">
            <validation-provider v-slot="{ errors }" name="金額" rules="required|max:100">
              <v-text-field
                type="number"
                v-model.number="creator.amount"
                :rules="[(v) => !!v || '金額 は必須です']"
                counter
                :maxlength="10"
                :error-messages="errors"
                label="*金額"
                clearable
                suffix="円"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <validation-provider v-slot="{ errors }" name="伝票サマリ種別" rules="">
              <v-select
                label="*伝票サマリ種別"
                v-model="creator.salesummaryType"
                :items="[
                  { text: 'なし', value: 1 },
                  { text: '日', value: 1 << 1 },
                  { text: '月', value: 1 << 2 },
                  { text: '年', value: 1 << 5 },
                ]"
                :error-messages="errors"
                required
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-divider class="mb-6" />

        <v-row>
          <v-col cols="3">
            <validation-provider v-slot="{ errors }" name="請求内容" rules="">
              <v-select
                label="請求内容"
                v-model="creator.invoice"
                :items="invoices"
                item-text="name"
                item-value="code"
                :error-messages="errors"
                return-object
                required
                outlined
              />
            </validation-provider>
          </v-col>

          <v-col cols="3">
            <validation-provider v-slot="{ errors }" name="支店" rules="required">
              <v-select
                label="支店"
                item-text="name"
                item-value="code"
                :items="branchs"
                v-model="creator.branch"
                :rules="[(v) => !!v.code || '支店 は必須です']"
                :error-messages="errors"
                return-object
              />
            </validation-provider>
          </v-col>

          <v-col cols="3">
            <validation-provider v-slot="{ errors }" name="チーム" rules="required">
              <v-select
                label="チーム"
                item-text="name"
                item-value="code"
                :items="teams"
                v-model="creator.team"
                :rules="[(v) => !!v.code || 'チーム は必須です']"
                :error-messages="errors"
                return-object
              />
            </validation-provider>
          </v-col>

          <v-col cols="3">
            <validation-provider v-slot="{ errors }" name="担当者" rules="required">
              <v-select
                v-model="creator.employee"
                :items="employees"
                label="担当者"
                item-text="name"
                item-value="code"
                return-object
                :rules="[(v) => !!v.code || '担当者 は必須です']"
                :error-messages="errors"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" name="顧客名" rules="">
              <v-select
                v-model="creator.facility"
                :items="facilities"
                item-text="name"
                item-value="code"
                label="顧客名"
                :error-messages="errors"
                return-object
                required
              />
            </validation-provider>
          </v-col>

          <v-col cols="2">
            <validation-provider v-slot="{ errors }" name="都道府県" rules="">
              <v-select
                v-model="creator.facility.pref"
                :items="prefs"
                label="都道府県"
                item-text="name"
                item-value="code"
                :error-messages="errors"
                return-object
                required
                outlined
                readonly
              />
            </validation-provider>
          </v-col>

          <v-col cols="4">
            <validation-provider v-slot="{ errors }" name="地域" rules="">
              <v-text-field v-model="creator.facility.areaName" label="地域" counter :maxlength="100" :error-messages="errors" readonly />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
              <v-textarea v-model="creator.memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
            </validation-provider>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  components: {},

  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          console.log('reset');
          this.creator.date = new Date();
          this.creator.invoiceDate = null;
          this.creator.depositDate = null;
          this.creator.type = 1;
          this.creator.salesummaryType = 1;
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  data: () => ({
    //
    initializing: true,

    date: null,

    prefs: [],
    facilities: [],
    employees: [],
    invoices: [],
    branchs: [],
    teams: [],

    datas: [],

    creator: {
      show: false,

      facility: {
        code: null,
        name: null,
        pref: {
          code: null,
          name: null,
        },
        areaName: null,
      },
      invoice: {
        code: null,
        name: null,
      },
      branch: {
        code: null,
        name: null,
      },
      team: {
        code: null,
        name: null,
      },
      employee: {
        code: null,
        name: null,
      },

      description: null,
      amount: null,
      date: new Date(),
      invoiceDate: null,
      depositDate: null,
      type: 1,
      salesummaryType: 1,
      memo: null,
    },
  }),

  methods: {
    onLoaded() {
      this.form.reset();
      // FIXME 本当はasync結果にするべき
      this.initializing = false;

      // 初期表示時は当月月初を指定する
      if (this.date == null) {
        this.date = this.$moment().add(0, 'months').startOf('month').format('YYYY-MM-DD');
      }

      this.get('prefs').then((success) => {
        let data = success.data;
        this.prefs = data;
      });

      this.get('Facilities').then((success) => {
        let data = success.data;
        this.facilities = data;
      });

      this.get('employees').then((success) => {
        let data = success.data;
        this.employees = data;
      });

      this.get('invoices').then((success) => {
        let data = success.data;
        this.invoices = data;
      });

      this.get('branches').then((success) => {
        let data = success.data;
        this.branchs = data;
      });

      this.get('teams').then((success) => {
        let data = success.data;
        this.teams = data;
      });
    },

    onCancelCreator() {
      this.$emit('cancel');
    },

    async onCommitCreator() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      let request = {
        facilityCode: this.creator.facility.code,
        facilityName: this.creator.facility.name,
        prefCode: this.creator.facility.pref.code,
        areaName: this.creator.facility.areaName,
        invoiceCode: this.creator.invoice.code,
        description: this.creator.description,
        type: this.creator.type,
        salesummaryType: this.creator.salesummaryType,
        amount: this.creator.amount,
        branchCode: this.creator.branch.code,
        teamCode: this.creator.team.code,
        employeeCode: this.creator.employee.code,
        date: this.convertDate(this.creator.date),
        invoiceDate: this.convertDate(this.creator.invoiceDate),
        depositDate: this.convertDate(this.creator.depositDate),
        memo: this.creator.memo,
      };

      // nullable
      request.amount = this.convertNumber(request.amount);

      this.post('sale', request).then((success) => {
        let data = success.data;
        this.$emit('commit', data);
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>