<template>
  <v-card>
    <v-card-title>
      <v-btn text color="warning" @click="onCancelEditor">閉じる</v-btn>
      <v-spacer />
      編集
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitEditor">決定</v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <v-form v-if="loaded">
            <v-row>
              <v-col cols="4">
                <XFieldSet label="売上日">
                  <validation-provider v-slot="{ errors }" name="売上日" rules="">
                    <DateTime label="売上日" v-model="editor.date" :error-messages="errors" />
                  </validation-provider>
                </XFieldSet>
              </v-col>

              <v-col cols="4">
                <XFieldSet label="請求書発行日">
                  <validation-provider v-slot="{ errors }" name="請求書発行日" rules="">
                    <DateTime label="請求書発行日" v-model="editor.invoiceDate" :error-messages="errors" />
                  </validation-provider>
                </XFieldSet>
              </v-col>

              <v-col cols="4">
                <XFieldSet label="入金日">
                  <validation-provider v-slot="{ errors }" name="入金日" rules="">
                    <DateTime label="入金日" v-model="editor.depositDate" :error-messages="errors" />
                  </validation-provider>
                </XFieldSet>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <validation-provider v-slot="{ errors }" name="区分" rules="">
                  <v-select
                    label="区分"
                    v-model="editor.type"
                    :items="[
                      { text: $options.filters.saleType(1), value: 1 },
                      { text: $options.filters.saleType(1 << 1), value: 1 << 1 },
                      { text: $options.filters.saleType(1 << 2), value: 1 << 2 },
                      { text: $options.filters.saleType(1 << 3), value: 1 << 3 },
                      { text: $options.filters.saleType(1 << 5), value: 1 << 5 },
                    ]"
                    :error-messages="errors"
                    required
                    outlined
                  />
                </validation-provider>
              </v-col>

              <v-col cols="4">
                <validation-provider v-slot="{ errors }" name="商品名/摘要" rules="max:100">
                  <v-text-field label="商品名/摘要" v-model="editor.description" counter :maxlength="100" :error-messages="errors" />
                </validation-provider>
              </v-col>

              <v-col cols="4">
                <validation-provider v-slot="{ errors }" name="金額" rules="max:100">
                  <v-text-field
                    type="number"
                    v-model.number="editor.amount"
                    label="金額"
                    counter
                    :maxlength="10"
                    :error-messages="errors"
                    clearable
                    suffix="円"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <validation-provider v-slot="{ errors }" name="伝票サマリ種別" rules="">
                  <v-select
                    label="伝票サマリ種別"
                    v-model="editor.salesummaryType"
                    :items="[
                      { text: 'なし', value: 1 },
                      { text: '日', value: 1 << 1 },
                      { text: '月', value: 1 << 2 },
                      { text: '年', value: 1 << 5 },
                    ]"
                    :error-messages="errors"
                    required
                    outlined
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-divider class="mb-6" />

            <v-row>
              <v-col cols="3">
                <validation-provider v-slot="{ errors }" name="請求内容" rules="">
                  <v-select
                    label="請求内容"
                    v-model="editor.invoice"
                    :items="invoices"
                    item-text="name"
                    item-value="code"
                    :error-messages="errors"
                    return-object
                    required
                    outlined
                  />
                </validation-provider>
              </v-col>

              <v-col cols="3">
                <validation-provider v-slot="{ errors }" name="支店" rules="">
                  <v-select
                    label="支店"
                    item-text="name"
                    item-value="code"
                    :items="branchs"
                    v-model="editor.branch"
                    :error-messages="errors"
                    return-object
                  />
                </validation-provider>
              </v-col>

              <v-col cols="3">
                <validation-provider v-slot="{ errors }" name="チーム" rules="">
                  <v-select
                    label="チーム"
                    item-text="name"
                    item-value="code"
                    :items="teams"
                    v-model="editor.team"
                    :error-messages="errors"
                    return-object
                  />
                </validation-provider>
              </v-col>

              <v-col cols="3">
                <validation-provider v-slot="{ errors }" name="担当者" rules="">
                  <v-select
                    v-model="editor.employee"
                    :items="employees"
                    label="担当者"
                    item-text="name"
                    item-value="code"
                    return-object
                    :error-messages="errors"
                    outlined
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <validation-provider v-slot="{ errors }" name="顧客名" rules="required">
                  <v-select
                    v-model="editor.facility"
                    :items="facilities"
                    item-text="name"
                    item-value="code"
                    label="*顧客名"
                    :rules="[(v) => !!v || '顧客名 は必須です']"
                    :error-messages="errors"
                    :prepend-inner-icon="!isEmpty(editor.facility.code) ? `mdi-check` : ``"
                    return-object
                    required
                  />
                </validation-provider>
              </v-col>

              <v-col cols="2">
                <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
                  <v-select
                    v-model="editor.facility.pref"
                    :items="prefs"
                    label="*都道府県"
                    item-text="name"
                    item-value="code"
                    :rules="[(v) => !!v || '都道府県 は必須です']"
                    :error-messages="errors"
                    return-object
                    required
                    readonly
                    outlined
                  />
                </validation-provider>
              </v-col>

              <v-col cols="4">
                <validation-provider v-slot="{ errors }" name="地域" rules="">
                  <v-text-field
                    v-model="editor.facility.areaName"
                    label="地域"
                    counter
                    :maxlength="100"
                    :error-messages="errors"
                    readonly
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                  <v-textarea v-model="editor.memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </template>
      </XSheet>
    </v-card-text>

    <v-card-actions>
      <v-btn color="red" @click="onDeleteEditor"><v-icon>mdi-delete</v-icon> 削除</v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },
    saleCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  data: () => ({
    //
    initializing: true,

    date: null,

    prefs: [],
    facilities: [],
    employees: [],
    invoices: [],
    branchs: [],
    teams: [],

    editor: {
      show: false,

      facility: {
        code: null,
        name: null,
        pref: {
          code: null,
          name: null,
        },
        areaName: null,
      },
      invoice: {
        code: null,
        name: null,
      },
      branch: {
        code: null,
        name: null,
      },
      team: {
        code: null,
        name: null,
      },
      employee: {
        code: null,
        name: null,
      },

      description: null,
      amount: 0,
      date: null,
      invoiceDate: null,
      depositDate: null,
      memo: null,
    },
  }),

  methods: {
    onLoaded() {
      // 初期表示時は当月月初を指定する
      if (this.date == null) {
        this.date = this.$moment().add(0, 'months').startOf('month').format('YYYY-MM-DD');
      }

      this.get('prefs').then((success) => {
        let data = success.data;
        this.prefs = data;
      });

      this.get('Facilities').then((success) => {
        let data = success.data;
        this.facilities = data;
      });

      this.get('employees').then((success) => {
        let data = success.data;
        this.employees = data;
      });

      this.get('invoices').then((success) => {
        let data = success.data;
        this.invoices = data;
      });

      this.get('branches').then((success) => {
        let data = success.data;
        this.branchs = data;
      });

      this.get('teams').then((success) => {
        let data = success.data;
        this.teams = data;
      });

      // FIXME 本来ならasyncさせるが暫定でここでやる
      this.initializing = true;

      this.get('sale', { saleCode: this.saleCode }).then((success) => {
        let data = success.data;

        this.editor.show = true;
        this.editor.facility = data.facility;
        this.editor.invoice = data.invoice;
        this.editor.branch = data.branch;
        this.editor.team = data.team;
        this.editor.employee = data.employee;
        this.editor.description = data.description;
        this.editor.type = data.type;
        this.editor.salesummaryType = data.salesummaryType;

        this.editor.amount = data.amount;
        this.editor.date = this.$moment(data.date).format('YYYY-MM-DD');
        this.editor.invoiceDate = this.$moment(data.invoiceDate).format('YYYY-MM-DD');
        this.editor.depositDate = this.$moment(data.depositDate).format('YYYY-MM-DD');

        this.editor.memo = data.memo;

        this.initializing = false;
      });
    },

    onCancelEditor() {
      this.$emit('cancel');
    },

    async onCommitEditor() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      let request = {
        saleCode: this.saleCode,
        description: this.description,
        type: this.editor.type,
        salesummaryType: this.editor.salesummaryType,
        amount: this.editor.amount,
        date: this.convertDate(this.editor.date),
        invoiceDate: this.convertDate(this.editor.invoiceDate),
        depositDate: this.convertDate(this.editor.depositDate),
        memo: this.editor.memo,
      };

      if (this.editor.facility != null) request.facilityCode = this.editor.facility.code;
      if (this.editor.facility != null) request.facilityName = this.editor.facility.name;
      if (this.editor.facility != null) request.prefCode = this.editor.facility.pref.code;
      if (this.editor.facility != null) request.areaName = this.editor.facility.areaName;
      if (this.editor.invoice != null) request.invoiceCode = this.editor.invoice.code;
      if (this.editor.branch != null) request.branchCode = this.editor.branch.code;
      if (this.editor.team != null) request.teamCode = this.editor.team.code;
      if (this.editor.employee != null) request.employeeCode = this.editor.employee.code;

      // nullable
      request.amount = this.convertNumber(request.amount);

      this.put('sale', request).then(() => {
        this.$emit('commit');
      });
    },

    onDeleteEditor() {
      if (!confirm('伝票を削除してもよろしいですか？')) return;

      this.delete('sale', {
        saleCode: this.saleCode,
      }).then(() => {
        this.$emit('delete');
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>