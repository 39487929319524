<template>
  <div>
    <v-data-table
      :headers="[
        { text: 'コード', value: 'code' },
        { text: '発行日', value: 'date' },
        { text: '請求日', value: 'invoiceDate' },
        { text: '入金日', value: 'depositDate' },
        { text: '種別', value: 'type' },
        { text: 'サマリ', value: 'salesummaryType' },
        { text: '支店', value: 'branch.name' },
        { text: 'チーム', value: 'team' },
        { text: '担当者', value: 'employee.name' },
        { text: '得意先', value: 'facility.name' },
        { text: '都道府県', value: 'facility.pref.name' },
        { text: 'エリア', value: 'facility.areaName' },
        { text: '請求内容', value: 'invoice.name' },
        { text: '商品名/摘要', value: 'description' },
        { text: '金額', value: 'amount' },
        { text: 'デモ', value: 'demo' },
        { text: '備考', value: 'memo' },
        { text: '編集', value: 'action' },
      ]"
      :items="datas"
      class="elevation-1"
      :items-per-page="-1"
      dense
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-title>
            <v-sheet width="150">
              <v-select v-model="date" :items="dates" solo @change="onDateChanged" />
            </v-sheet>

            <v-spacer />
            <v-btn @click="onShowCreator"> <v-icon small>mdi-pen-plus</v-icon>追加 </v-btn>
          </v-card-title>
        </v-card>
      </template>

      <template v-slot:item.code="{ item }">
        {{ item.code | lessStr(5, '..') }}
      </template>

      <template v-slot:item.type="{ item }">
        <v-chip :color="$options.filters.saleTypeColor(item.type)" small outlined>
          {{ item.type | saleType }}
        </v-chip>
      </template>

      <template v-slot:item.salesummaryType="{ item }">
        <v-chip :color="$options.filters.saleSummaryTypeColor(item.salesummaryType)" small>
          {{ item.salesummaryType | saleSummaryType }}</v-chip
        >
      </template>

      <template v-slot:item.date="{ item }">
        {{ item.date | date }}
      </template>
      <template v-slot:item.invoiceDate="{ item }">
        <v-chip v-if="item.invoiceDate == null" color="warning" small>見込み</v-chip>
        <span v-else>{{ item.invoiceDate | date }}</span>
      </template>
      <template v-slot:item.depositDate="{ item }">
        {{ item.depositDate | date }}
      </template>

      <template v-slot:item.team="{ item }">
        <v-btn
          v-if="item.team != null"
          class="custom-transform-class text-none"
          text
          color="primary"
          @click="
            {
              teamEditor.code = item.team.code;
              teamEditor.show = true;
            }
          "
          >{{ item.team.name | lessStr(5, '..') }}</v-btn
        >
      </template>

      <template v-slot:item.demo="{ item }">
        <template v-if="item.demo != null">
          <v-btn
            text
            color="primary"
            @click="
              {
                saleEditor.code = item.demo.code;
                saleEditor.show = true;
              }
            "
            >{{ item.demo.code | lessStr(5, '..') }}</v-btn
          >
        </template>
      </template>

      <template v-slot:item.amount="{ item }">
        {{ item.amount | priceJP }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click.stop="onShowEditor(item)"> mdi-form-select </v-icon>
      </template>
    </v-data-table>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent v-model="creator.show" inset>
        <Create :show="creator.show" :observer="observer" @commit="onCommitCreator" @cancel="creator.show = false" />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent v-model="editor.show" inset>
        <Edit
          :show="editor.show"
          :observer="observer"
          :saleCode="editor.code"
          @commit="onCommitEditor"
          @cancel="editor.show = false"
          @delete="onDeleteEditor"
        />
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable max-width="1000" v-model="teamEditor.show" inset>
        <TeamEditor :show="teamEditor.show" :observer="observer" :teamCode="teamEditor.code">
          <template v-slot:close>
            <v-btn text color="warning" @click="teamEditor.show = false">閉じる</v-btn>
          </template>
        </TeamEditor>
      </v-bottom-sheet>
    </ValidationObserver>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="saleEditor.show" inset>
        <SaleEdit
          :show="saleEditor.show"
          :observer="observer"
          :demoCode="saleEditor.code"
          @commit="onSaleEdit"
          @error="saleEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="saleEditor.show = false">閉じる</v-btn>
          </template>
        </SaleEdit>
      </v-bottom-sheet>
    </ValidationObserver>
  </div>
</template>


<script>
import Create from './creates/Create';
import Edit from './edits/Edit';

import SaleEdit from '@/components/sales/Edit';

import TeamEditor from '@/components/teams/Edit';

export default {
  components: { Create, Edit, TeamEditor, SaleEdit },
  computed: {
    dates() {
      let d = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

      // return [{text:m.format("YYYY-MM-DD"),value:m.toString("yyyyMMdd")}]

      return [...Array(5).keys()].map((a) => {
        let m = this.$moment(d).add(a - 2, 'months');
        return {
          text: m.format('YYYY-MM'),
          value: m.format('YYYY-MM-DD'),
        };
      });
    },
  },

  data: () => ({
    date: null,

    datas: [],

    creator: {
      show: false,
    },

    editor: {
      show: false,
      code: null,
    },

    teamEditor: {
      show: false,
      code: null,
    },

    saleEditor: {
      show: false,
      code: null,
    },
  }),

  methods: {
    onLoaded() {
      // 初期表示時は当月月初を指定する
      if (this.date == null) {
        this.date = this.$moment().add(0, 'months').startOf('month').format('YYYY-MM-DD');
      }

      this.get('sales', { date: this.date }).then((success) => {
        let data = success.data;
        this.datas = data;

        // 初期化
        // this.datas = [];
        // this.datas.push({
        //   code: "aa",
        //   date: new Date(),

        //   branch: {
        //     name: "本社",
        //   },

        //   team: {
        //     name: "サンプルチーム",
        //   },
        //   employee: {
        //     name: "テスト",
        //   },

        //   facility: {
        //     name: "李馬蹄",
        //     areaName: "中部",
        //   },
        //   pref: {
        //     name: "静岡",
        //   },

        //   invoice: {
        //     date: new Date(),
        //     type: "HP保守料",
        //   },
        //   description: "サーバーレンタル料",
        //   amount: 5100,
        //   memo: "サンプルです",
        // });
      });
    },

    onDateChanged() {
      // リロードする
      this.onLoaded();
    },

    onShowCreator() {
      this.creator.show = true;
    },

    onShowEditor(sale) {
      this.editor.code = sale.code;

      this.editor.show = true;
    },

    onCancelCreator() {
      this.creator.show = false;
    },

    onCommitCreator(data) {
      this.datas.push(data);

      this.creator.show = false;
    },

    onCancelEditor() {
      this.editor.show = false;
    },

    onCommitEditor() {
      this.onLoaded();
      this.editor.show = false;
    },

    onDeleteEditor() {
      this.onLoaded();
      this.editor.show = false;
    },

    onSaleEdit() {
      this.saleEditor.show = false;
      // リロードする
      this.onLoaded();
    },
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>