var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":_vm.onCancelEditor}},[_vm._v("閉じる")]),_c('v-spacer'),_vm._v(" 編集 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommitEditor}},[_vm._v("決定")])],1),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('XFieldSet',{attrs:{"label":"売上日"}},[_c('validation-provider',{attrs:{"name":"売上日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"売上日","error-messages":errors},model:{value:(_vm.editor.date),callback:function ($$v) {_vm.$set(_vm.editor, "date", $$v)},expression:"editor.date"}})]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('XFieldSet',{attrs:{"label":"請求書発行日"}},[_c('validation-provider',{attrs:{"name":"請求書発行日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"請求書発行日","error-messages":errors},model:{value:(_vm.editor.invoiceDate),callback:function ($$v) {_vm.$set(_vm.editor, "invoiceDate", $$v)},expression:"editor.invoiceDate"}})]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('XFieldSet',{attrs:{"label":"入金日"}},[_c('validation-provider',{attrs:{"name":"入金日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"入金日","error-messages":errors},model:{value:(_vm.editor.depositDate),callback:function ($$v) {_vm.$set(_vm.editor, "depositDate", $$v)},expression:"editor.depositDate"}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"区分","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"区分","items":[
                    { text: _vm.$options.filters.saleType(1), value: 1 },
                    { text: _vm.$options.filters.saleType(1 << 1), value: 1 << 1 },
                    { text: _vm.$options.filters.saleType(1 << 2), value: 1 << 2 },
                    { text: _vm.$options.filters.saleType(1 << 3), value: 1 << 3 },
                    { text: _vm.$options.filters.saleType(1 << 5), value: 1 << 5 } ],"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.editor.type),callback:function ($$v) {_vm.$set(_vm.editor, "type", $$v)},expression:"editor.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"商品名/摘要","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"商品名/摘要","counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.editor.description),callback:function ($$v) {_vm.$set(_vm.editor, "description", $$v)},expression:"editor.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"金額","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"金額","counter":"","maxlength":10,"error-messages":errors,"clearable":"","suffix":"円"},model:{value:(_vm.editor.amount),callback:function ($$v) {_vm.$set(_vm.editor, "amount", _vm._n($$v))},expression:"editor.amount"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"伝票サマリ種別","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"伝票サマリ種別","items":[
                    { text: 'なし', value: 1 },
                    { text: '日', value: 1 << 1 },
                    { text: '月', value: 1 << 2 },
                    { text: '年', value: 1 << 5 } ],"error-messages":errors,"required":"","outlined":""},model:{value:(_vm.editor.salesummaryType),callback:function ($$v) {_vm.$set(_vm.editor, "salesummaryType", $$v)},expression:"editor.salesummaryType"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"請求内容","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"請求内容","items":_vm.invoices,"item-text":"name","item-value":"code","error-messages":errors,"return-object":"","required":"","outlined":""},model:{value:(_vm.editor.invoice),callback:function ($$v) {_vm.$set(_vm.editor, "invoice", $$v)},expression:"editor.invoice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"支店","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"支店","item-text":"name","item-value":"code","items":_vm.branchs,"error-messages":errors,"return-object":""},model:{value:(_vm.editor.branch),callback:function ($$v) {_vm.$set(_vm.editor, "branch", $$v)},expression:"editor.branch"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"チーム","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"チーム","item-text":"name","item-value":"code","items":_vm.teams,"error-messages":errors,"return-object":""},model:{value:(_vm.editor.team),callback:function ($$v) {_vm.$set(_vm.editor, "team", $$v)},expression:"editor.team"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"担当者","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.employees,"label":"担当者","item-text":"name","item-value":"code","return-object":"","error-messages":errors,"outlined":""},model:{value:(_vm.editor.employee),callback:function ($$v) {_vm.$set(_vm.editor, "employee", $$v)},expression:"editor.employee"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"顧客名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.facilities,"item-text":"name","item-value":"code","label":"*顧客名","rules":[function (v) { return !!v || '顧客名 は必須です'; }],"error-messages":errors,"prepend-inner-icon":!_vm.isEmpty(_vm.editor.facility.code) ? "mdi-check" : "","return-object":"","required":""},model:{value:(_vm.editor.facility),callback:function ($$v) {_vm.$set(_vm.editor, "facility", $$v)},expression:"editor.facility"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"都道府県","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.prefs,"label":"*都道府県","item-text":"name","item-value":"code","rules":[function (v) { return !!v || '都道府県 は必須です'; }],"error-messages":errors,"return-object":"","required":"","readonly":"","outlined":""},model:{value:(_vm.editor.facility.pref),callback:function ($$v) {_vm.$set(_vm.editor.facility, "pref", $$v)},expression:"editor.facility.pref"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"地域","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"地域","counter":"","maxlength":100,"error-messages":errors,"readonly":""},model:{value:(_vm.editor.facility.areaName),callback:function ($$v) {_vm.$set(_vm.editor.facility, "areaName", $$v)},expression:"editor.facility.areaName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.editor.memo),callback:function ($$v) {_vm.$set(_vm.editor, "memo", $$v)},expression:"editor.memo"}})]}}],null,true)})],1)],1)],1):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.onDeleteEditor}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" 削除")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }